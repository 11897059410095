/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";

// Dashboard layout
import MainAvatar from "layouts/healthcare/components/MainAvatar";
import MainProfileInfo from "layouts/healthcare/components/mainProfileInfo";
import DoctorCard from "../components/Cards/DoctorCard";
import Footer from "examples/Footer";



// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function newAppointment() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mb="3">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={5}>
                <MainAvatar />
              </Grid>
              <Grid item xs={12} md={6} xl={7}>
                <MainProfileInfo />
              </Grid>
            </Grid>
            <Grid mt={3}>
              <Grid item xs={12} md={6} xl={12}>
                <VuiInput placeholder="Type here..." size="large" multiline rows={20}></VuiInput>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={4.5}></Grid>
              <Grid item xs={12} md={6} xl={3} mt={2}>
                <VuiButton variant="gradient" color="info" size="large">Send</VuiButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} xl={7}>
            <Grid container spacing={3}>
              {
                [1,2,3,4,5,6,7,8,9].map(() => (
                    <Grid item xs={12} md={6} xl={4}>
                      <DoctorCard />
                    </Grid>
                  )
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
      {/* <Footer></Footer> */}
    </DashboardLayout>
  );
}

export default newAppointment;
