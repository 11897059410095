import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import gif from "assets/images/healthcare.jpeg";
import { useHistory } from "react-router-dom";

const HealthBackImg = () => {
  let history = useHistory();
  function handleSubmitProfile () {
    
  }
  return (
    <Card sx={() => ({
      height: "500px",
      py: "32px",
      backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      cursor : 'pointer'
    })} onClick={handleSubmitProfile}>
    </Card>
  );
};

export default HealthBackImg;
