import React from "react";

import { Card, Icon, Modal, Backdrop, Fade, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput"; 
import { useHistory } from "react-router-dom";
import VuiButton from "components/VuiButton";

import * as GradientProgress from "@delowar/react-circle-progressbar";
import { IoHappy } from "react-icons/io5";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import image from "assets/images/logos/QRcode.png";

const HealthCare = () => {
  const { info, gradients } = colors;
  const { cardContent } = gradients;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
      setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };

  let history = useHistory();
  const handleSubmitHealthCare = () => {
    history.push("/Healthcare");
  }
  return (
    <Card sx={() => ({
      height: "340px",
      py: "32px",
      // backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      cursor: "pointer",
    })} onClick={handleOpen}>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="4px">
          Health status
        </VuiTypography>

        <VuiBox sx={{ alignSelf: "center", justifySelf: "center", zIndex: "-1" }}>
          <GradientProgress
            percent={80}
            viewport
            size={200}
            isGradient
            gradient={{
              angle: 90,
              startColor: "rgba(0, 117, 255, 0)",
              stopColor: info.main,
            }}
            emptyColor="#22234B"
          >
            <VuiBox
              sx={{
                background: info.main,
                transform: "translateY(-50%)",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IoHappy size="30px" color="#fff" />
            </VuiBox>
          </GradientProgress>
        </VuiBox>
        <VuiBox
          sx={({ breakpoints }) => ({
            width: "90%",
            padding: "18px 22px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            height: "82px",
            mx: "auto",
            borderRadius: "20px",
            background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
            transform: "translateY(-90%)",
            zIndex: "1000",
          })}
        >
          <VuiTypography color="text" variant="caption" display="inline-block" fontWeight="regular">
            0%
          </VuiTypography>
          <VuiBox
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ minWidth: "80px" }}
          >
            <VuiTypography color="white" variant="h3">
              95%
            </VuiTypography>
            <VuiTypography color="text" variant="caption" fontWeight="regular">
              {/* Based on likes */}
            </VuiTypography>
          </VuiBox>
          <VuiTypography color="text" variant="caption" display="inline-block" fontWeight="regular">
            100%
          </VuiTypography>
        </VuiBox>
      </VuiBox>      
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          // BackdropComponent={image}
          closeAfterTransition

          BackdropProps={{
              timeout: 500,
          }}
      >
          <Fade in={open}>
              <VuiBox lineHeight={1} style={{ width: "600px", background: "black", borderRadius: "15px" }} >
                  <div style={{ margin: "30px" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={3} md={3} xl={6}>
                          <Card sx={() => ({
                            // height: "345px",
                            width:"200px",
                            height:"200px",
                            py: "32px",
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "50%",
                            cursor : 'pointer'
                          })}>
                          </Card>
                        </Grid>
                        <Grid item xs={3} md={3} xl={6} mt={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={3} md={3} xl={8}>
                              <VuiInput color="success" placeholder="phone number"></VuiInput>
                            </Grid>
                            <Grid item xs={3} md={3} xl={4}>
                              <VuiButton color="primary">Send</VuiButton>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} mt={1}>
                            <Grid item xs={3} md={3} xl={8}>
                              <VuiInput color="success" placeholder="Code Verify"></VuiInput>
                            </Grid>
                            <Grid item xs={3} md={3} xl={4}>
                              <VuiButton color="success" onClick={handleSubmitHealthCare}>Verify</VuiButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                  </div>
              </VuiBox>
          </Fade>
      </Modal>                                                                                                                                                                                                                                                                                                                                                               
    </Card>
  );
};

export default HealthCare;
