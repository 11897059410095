/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import VuiAlert from "components/VuiAlert"

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/security/components/WelcomeMark";
import Projects from "layouts/security/components/Projects";
import OrderOverview from "layouts/security/components/OrderOverview";
import SatisfactionRate from "layouts/security/components/SatisfactionRate";
import ReferralTracking from "layouts/security/components/ReferralTracking";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import { lineChartDataDashboard } from "layouts/security/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/security/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/security/data/barChartData";
import { barChartOptionsDashboard } from "layouts/security/data/barChartOptions";

function Security() {
  const { gradients } = colors;
  const { cardContent } = gradients;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <VuiBox py={3}>
            <VuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={3}>
                  {/* <MiniStatisticsCard
                    title={{ text: "today's money", fontWeight: "regular" }}
                    count="$53,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "info", component: <IoWallet size="22px" color="white" /> }}
                  /> */}
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  {/* <MiniStatisticsCard
                    title={{ text: "today's users" }}
                    count="2,300"
                    percentage={{ color: "success", text: "+3%" }}
                    icon={{ color: "info", component: <IoGlobe size="22px" color="white" /> }}
                  /> */}
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  {/* <MiniStatisticsCard
                    title={{ text: "new clients" }}
                    count="+3,462"
                    percentage={{ color: "error", text: "-2%" }}
                    icon={{ color: "info", component: <IoDocumentText size="22px" color="white" /> }}
                  /> */}
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  {/* <MiniStatisticsCard
                    title={{ text: "total sales" }}
                    count="$103,430"
                    percentage={{ color: "success", text: "+5%" }}
                    icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
                  /> */}
                </Grid>
              </Grid>
            </VuiBox>
            <VuiBox mb={3}>
              <Grid container spacing="18px">
                <Grid item xs={12} lg={12} xl={5}>
                  <WelcomeMark />
                </Grid>
                <Grid item xs={12} lg={6} xl={3}>
                  {/* <SatisfactionRate /> */}
                  <VuiAlert color="dark">
                  Security log for the Security Tab which will house all the clients security info/Authentication info/ and what contracts the client has authorized and who has access to their info and what levels of access they have.
                  </VuiAlert>
                </Grid>
                <Grid item xs={12} lg={6} xl={4}>
                  {/* <ReferralTracking /> */}
                </Grid>
              </Grid>
            </VuiBox>
            {/* <VuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} xl={7}>
                  <Card>
                    <VuiBox sx={{ height: "100%" }}>
                      <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                        Sales Overview
                      </VuiTypography>
                      <VuiBox display="flex" alignItems="center" mb="40px">
                        <VuiTypography variant="button" color="success" fontWeight="bold">
                          +5% more{" "}
                          <VuiTypography variant="button" color="text" fontWeight="regular">
                            in 2021
                          </VuiTypography>
                        </VuiTypography>
                      </VuiBox>
                      <VuiBox sx={{ height: "310px" }}>
                        <LineChart
                          lineChartData={lineChartDataDashboard}
                          lineChartOptions={lineChartOptionsDashboard}
                        />
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6} xl={5}>
                  <Card>
                    <VuiBox>
                      <VuiBox
                        mb="24px"
                        height="220px"
                        sx={{
                          background: linearGradient(
                            cardContent.main,
                            cardContent.state,
                            cardContent.deg
                          ),
                          borderRadius: "20px",
                        }}
                      >
                        <BarChart
                          barChartData={barChartDataDashboard}
                          barChartOptions={barChartOptionsDashboard}
                        />
                      </VuiBox>
                      <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                        Active Users
                      </VuiTypography>
                      <VuiBox display="flex" alignItems="center" mb="40px">
                        <VuiTypography variant="button" color="success" fontWeight="bold">
                          (+23){" "}
                          <VuiTypography variant="button" color="text" fontWeight="regular">
                            than last week
                          </VuiTypography>
                        </VuiTypography>
                      </VuiBox>
                      <Grid container spacing="50px">
                        <Grid item xs={6} md={3} lg={3}>
                          <Stack
                            direction="row"
                            spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                            mb="6px"
                          >
                            <VuiBox
                              bgColor="info"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                            >
                              <IoWallet color="#fff" size="12px" />
                            </VuiBox>
                            <VuiTypography color="text" variant="button" fontWeight="medium">
                              Users
                            </VuiTypography>
                          </Stack>
                          <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                            32,984
                          </VuiTypography>
                          <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                        </Grid>
                        <Grid item xs={6} md={3} lg={3}>
                          <Stack
                            direction="row"
                            spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                            mb="6px"
                          >
                            <VuiBox
                              bgColor="info"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                            >
                              <IoIosRocket color="#fff" size="12px" />
                            </VuiBox>
                            <VuiTypography color="text" variant="button" fontWeight="medium">
                              Clicks
                            </VuiTypography>
                          </Stack>
                          <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                            2,42M
                          </VuiTypography>
                          <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                        </Grid>
                        <Grid item xs={6} md={3} lg={3}>
                          <Stack
                            direction="row"
                            spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                            mb="6px"
                          >
                            <VuiBox
                              bgColor="info"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                            >
                              <FaShoppingCart color="#fff" size="12px" />
                            </VuiBox>
                            <VuiTypography color="text" variant="button" fontWeight="medium">
                              Sales
                            </VuiTypography>
                          </Stack>
                          <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                            2,400$
                          </VuiTypography>
                          <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                        </Grid>
                        <Grid item xs={6} md={3} lg={3}>
                          <Stack
                            direction="row"
                            spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                            mb="6px"
                          >
                            <VuiBox
                              bgColor="info"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                            >
                              <IoBuild color="#fff" size="12px" />
                            </VuiBox>
                            <VuiTypography color="text" variant="button" fontWeight="medium">
                              Items
                            </VuiTypography>
                          </Stack>
                          <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                            320
                          </VuiTypography>
                          <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                        </Grid>
                      </Grid>
                    </VuiBox>
                  </Card>
                </Grid>
              </Grid>
            </VuiBox> */}
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
              <Grid item xs={12} md={6} lg={8}>
                {/* <Projects /> */}
                <OrderOverview />

              </Grid>
              <Grid item xs={12} md={6} lg={4}>
              </Grid>
            </Grid>
          </VuiBox>
        </Grid>
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Security;
