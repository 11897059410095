import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useHistory } from "react-router-dom";

import image from "assets/images/logos/security1.png";

const Security = () => {
  let history = useHistory();
  const handleClickSSecurity = () => {
    console.log("This is Security page");
    history.push("/security");
  }
  return (
    <Card sx={() => ({
      height: "340px",
      py: "32px",
      // backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      cursor: "pointer",
    })} onClick={handleClickSSecurity}>
      <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
        Security
      </VuiTypography>
      <VuiBox
        component="img"
        src={image}
        mb="8px"
        ml="50px"
        borderRadius="15px"
        width="250px"
        height="250px"
      />
    </Card>
  );
};

export default Security;
