import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import AppointmentCard from "../components/Cards/AppointmentCard";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function newAppointment() {
    let history = useHistory();
    function handleCreateAppointment() {
        history.push("/healthcare/appointments/new");
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ textAlign: "center", padding: "20px" }}>
                <Button variant="contained" style={{ color: "white" }} onClick={handleCreateAppointment}>Create One</Button>
            </div>
            <div style={{ padding: "30px", color: "white" }}>Upcoming</div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
            </Grid>
            <div style={{ padding: "30px", color: "white" }}>Past</div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                    <AppointmentCard />
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}