import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import gif from "assets/images/logos/lock.PNG";
import { useHistory } from "react-router-dom";
// import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import image from "assets/images/logos/store.png";

const Lock = () => {
  let history = useHistory();
  const handleSubmitLock = () => {
    history.push('/storage');
  }
  return (
    <Card sx={() => ({
      height: "340px",
      py: "32px",
      // backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      cursor: "pointer"
    })} onClick = {handleSubmitLock}>
      <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
        Storage
      </VuiTypography>
      <VuiBox
        component="img"
        src={image}
        mb="8px"
        ml="50px"
        borderRadius="15px"
        width="250px"
        height="250px"
      />
    </Card>
  );
};

export default Lock;
