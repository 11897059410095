import React from "react";

import { Card, Icon, Modal, Backdrop, Fade } from "@mui/material";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import gif from "assets/images/logos/appointment.png";
import { useHistory } from "react-router-dom";
import DoctorCard from "../Cards/DoctorCard";
// import ProfilesList from "examples/Lists/ProfilesList/index";

const mainProfileInfo = () => {
    let history = useHistory();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function handleSubmitProfile() {

    }

    return (
        <Card sx={() => ({
            //   height: "300px",
            py: "32px",
            //   backgroundImage: `url(${gif})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            cursor: 'pointer'
        })} onClick={handleSubmitProfile}>
            <VuiTypography variant="lg" color="white" fontWeight="light">
                Profile Info
            </VuiTypography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={5}>
                            <VuiBox color="white" ml={2}>Name : </VuiBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={7}>
                            <VuiBox color="white"> Mark Johnson</VuiBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={5}>
                            <VuiBox color="white" ml={4}>Sex : </VuiBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={7}>
                            <VuiBox color="white"> male </VuiBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={5}>
                            <VuiBox color="white" ml={4}>Age:</VuiBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={7}>
                            <VuiBox color="white"> 12 </VuiBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={5}>
                    <VuiBox color="white">Address:</VuiBox>
                </Grid>
                <Grid item xs={12} md={6} xl={7}>
                    <VuiBox color="white"> Kinkerstraat 112, 1053 ED Amsterdam </VuiBox>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={5} mt={1}>
                    <VuiBox color="white">Doctor : </VuiBox>
                </Grid>
                <Grid item xs={12} md={6} xl={7} mt={1}>
                    <VuiButton variant="outlined" onClick={handleOpen}>Select Doctor</VuiButton>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                closeAfterTransition
            >
                <Fade in={open}>
                    <VuiBox lineHeight={1} style={{ width: "1200px", background: "black", borderRadius: "15px" }} >
                        <div style={{ margin: "30px" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={3} md={3} xl={3}>
                                    <Card sx={{ height: "100%"}}>
                                        <VuiInput
                                            placeholder="Type here..."
                                            icon={{ component: "search", direction: "left" }}
                                            sx={({ breakpoints }) => ({
                                                [breakpoints.down("sm")]: {
                                                    maxWidth: "80px",
                                                },
                                                [breakpoints.only("sm")]: {
                                                    maxWidth: "80px",
                                                },
                                                backgroundColor: "info.main !important",
                                            })}
                                        />

                                        <div style={{textAlign: "center", color:"white", paddingTop: "15px", paddingBottom: "15px"}}>Categories</div>
                                        {
                                            ["Epidemiologist", "Audiologist", "Dentist", "Podiatrist", "Paediatrician", "General medical practitioner", "Psychiatrist", "Geriatric physician", "Endocrinologist", "Rheumatologist"].map((row) => (
                                                <div style={{ color: "white", paddingTop:"10px", fontSize:"15px" }}>{row}</div>
                                            ))
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={9} md={9} xl={9}>
                                    <Grid container spacing={3}>
                                        {
                                            [1, 2, 3, 4, 5, 6].map(() => (
                                                <Grid item xs={12} md={6} xl={4}>
                                                    <DoctorCard />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </VuiBox>
                </Fade>
            </Modal>
        </Card >
    );
};

export default mainProfileInfo;
