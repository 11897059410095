/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Images
import AdobeXD from "examples/Icons/AdobeXD";
import Atlassian from "examples/Icons/Atlassian";
import Slack from "examples/Icons/Slack";
import Spotify from "examples/Icons/Spotify";
import Jira from "examples/Icons/Jira";
import Invision from "examples/Icons/Invision";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoWebDev from "assets/images/small-logos/logo-webdev.svg";
import logoXD from "assets/images/small-logos/logo-xd.svg";

import { BsCurrencyBitcoin } from "react-icons/bs";

function Completion({ value, color }) {
  return (
    <VuiBox display="flex" flexDirection="column" alignItems="flex-start">
      <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
        {value}%&nbsp;
      </VuiTypography>
      <VuiBox width="8rem">
        <VuiProgress value={value} color={color} sx={{ background: "#2D2E5F" }} label={false} />
      </VuiBox>
    </VuiBox>
  );
}

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);

export default {
  columns: [
    { name: "Name", align: "left" },
    { name: "Symbol", align: "left" },
    { name: "Amount", align: "left" },
    { name: "Decimal", align: "left" },
  ],

  rows: [
    {
      Name: (
        <VuiBox display="flex" alignItems="center">
          {/* <BsCurrencyBitcoin size="20px" /> */}
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            Bitcoin
          </VuiTypography>
        </VuiBox>
      ),
      Symbol: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="10px" color="white" variant="button" fontWeight="medium">
            BTC
          </VuiTypography>
        </VuiBox>
      ),
      Amount: (
        <VuiTypography  pl="15px" variant="button" color="white" fontWeight="medium">
          10
        </VuiTypography>
      ),
      Decimal: (
        <VuiTypography pl="35px" variant="button" color="white" fontWeight="medium">
          18
        </VuiTypography>
      ),
      completion: <Completion value={60} color="info" />,
      action,
    },
    {
      Name: (
        <VuiBox display="flex" alignItems="center">
          {/* <AdobeXD size="20px" /> */}
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            Ethereum
          </VuiTypography>
        </VuiBox>
      ),
      Symbol: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="10px" color="white" variant="button" fontWeight="medium">
            ETH
          </VuiTypography>
        </VuiBox>
      ),
      Amount: (
        <VuiTypography  pl="15px" variant="button" color="white" fontWeight="medium">
          10
        </VuiTypography>
      ),
      Decimal: (
        <VuiTypography pl="35px" variant="button" color="white" fontWeight="medium">
          18
        </VuiTypography>
      ),
      completion: <Completion value={60} color="info" />,
      action,
    },
    {
      Name: (
        <VuiBox display="flex" alignItems="center">
          {/* <AdobeXD size="20px" /> */}
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            Binance
          </VuiTypography>
        </VuiBox>
      ),
      Symbol: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="10px" color="white" variant="button" fontWeight="medium">
            BNB
          </VuiTypography>
        </VuiBox>
      ),
      Amount: (
        <VuiTypography  pl="15px" variant="button" color="white" fontWeight="medium">
          10
        </VuiTypography>
      ),
      Decimal: (
        <VuiTypography pl="35px" variant="button" color="white" fontWeight="medium">
          18
        </VuiTypography>
      ),
      completion: <Completion value={60} color="info" />,
      action,
    },
    {
      Name: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            Tether
          </VuiTypography>
        </VuiBox>
      ),
      Symbol: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="10px" color="white" variant="button" fontWeight="medium">
            USDT
          </VuiTypography>
        </VuiBox>
      ),
      Amount: (
        <VuiTypography  pl="15px" variant="button" color="white" fontWeight="medium">
          10
        </VuiTypography>
      ),
      Decimal: (
        <VuiTypography pl="35px" variant="button" color="white" fontWeight="medium">
          18
        </VuiTypography>
      ),
      completion: <Completion value={60} color="info" />,
      action,
    },
    {
      Name: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            Solona
          </VuiTypography>
        </VuiBox>
      ),
      Symbol: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="10px" color="white" variant="button" fontWeight="medium">
            Sol
          </VuiTypography>
        </VuiBox>
      ),
      Amount: (
        <VuiTypography  pl="15px" variant="button" color="white" fontWeight="medium">
          10
        </VuiTypography>
      ),
      Decimal: (
        <VuiTypography pl="35px" variant="button" color="white" fontWeight="medium">
          18
        </VuiTypography>
      ),
      completion: <Completion value={60} color="info" />,
      action,
    },
  ],
};
