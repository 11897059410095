import React from "react";

import { Card, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import avatar from "assets/images/avatar8.png"

import gif from "assets/images/logos/appointment.png";
import { useHistory } from "react-router-dom";

import VuiBadge from "components/VuiBadge";



const DoctorCard = () => {
    let history = useHistory();
    function handleSubmitProfile() {

    }
    return (
        <Card sx={() => ({
            // height: "420px",
            // py: "32px",
            backgroundSize: "cover",
            backgroundPosition: "50%",
            cursor: 'pointer'
        })} onClick={handleSubmitProfile}>
            <Grid container spacing={3} style={{paddingBottom: "15px"}}>
                <Grid item xs={12} md={12} xl={12}>
                        <VuiBadge badgeContent="Plus" container/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                    <VuiAvatar
                        src={avatar}
                        size="xl"
                        sx={({ borders: { borderWidth }, palette: { dark }, functions: { rgba } }) => ({
                            border: `${borderWidth[2]} solid ${rgba(dark.focus, 0.5)}`,
                            cursor: "pointer",
                            position: "relative",
                            ml: -1.25,

                            "&:hover, &:focus": {
                                zIndex: "10",
                            },
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={8}>
                    {/* <Grid container spacing={3}> */}
                    <Grid item xs={12} md={6} xl={12}>
                        <VuiTypography variant="button" color="white" ml={2} fontWeight="regular">Dr. Daniel </VuiTypography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={12}>
                        <VuiTypography variant="button" color="white" ml={2} fontWeight="regular">Core Doctor</VuiTypography>
                    </Grid>
                    {/* </Grid> */}
                </Grid>
            </Grid>
            <VuiBox mb={2} lineHeight={1} mt={2}>
                <VuiTypography variant="button" color="text" fontWeight="regular">
                    “He is very gracious, easy to talk to, efficient, and I felt confident with him…”
                </VuiTypography>
            </VuiBox>
        </Card>
    );
};

export default DoctorCard;
