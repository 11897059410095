/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard React base styles
import borders from "assets/theme/base/borders";

// Images
import colors from "assets/theme/base/colors";

// Vision UI Dashboard component exemples
import Mastercard from "examples/Icons/Mastercard";
import Visa from "examples/Icons/Visa";

function Staking() {
  const { grey } = colors;

  return (
    <Card id="delete-account">
      <VuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
              <VuiTypography variant="lg" fontWeight="bold" color="white">
                Staking
              </VuiTypography>
            </VuiBox>
            <VuiBox
              border="2px solid"
              borderRadius="20px"
              borderColor={grey[600]}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="22px 20px"
            >
              <VuiTypography pl={2} variant="h1" color="white" fontWeight="medium">
                8000
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
              <VuiTypography variant="lg" fontWeight="bold" color="white">
                Holding
              </VuiTypography>
            </VuiBox>
            <VuiBox
              border="2px solid"
              borderRadius="20px"
              borderColor={grey[600]}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="22px 20px"
            >
              <VuiTypography pl={2} variant="h1" color="white" fontWeight="medium">
                10000
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
              <VuiTypography variant="lg" fontWeight="bold" color="white">
                Cold Storage
              </VuiTypography>
            </VuiBox>
            <VuiBox
              border="2px solid"
              borderRadius="20px"
              borderColor={grey[600]}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="22px 20px"
            >
              <VuiTypography pl={2} variant="h1" color="white" fontWeight="medium">
                5000
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

export default Staking;
