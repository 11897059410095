import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import gif from "assets/images/logos/appointment.png";
import { useHistory } from "react-router-dom";

const CategoryCard = (props) => {
  let history = useHistory();
  const { data } = props;
  function handleSubmitProfile() {
    history.push(data.url);
  }

  return (
    <Card className="LinkCard" sx={() => ({
      height: "200px",
      py: "60px",
      //   backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      // backgroundPosition: "50%",
      cursor: 'pointer',
    })} onClick={handleSubmitProfile}>
      <div style={{ textAlign: "center" }}>
        <VuiTypography variant="lg" color="white" fontWeight="light" mb="5px" verticalAlign="middle">
          <Icon fontSize="large" color="inherit">{data.icon} </Icon>
          <br />
          {data.title}
        </VuiTypography>
      </div>
    </Card>
  );
};

export default CategoryCard;
