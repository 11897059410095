export default [
    {
        title: "Appointments",
        url: "/healthcare/appointments",
        icon: "handshake"
    },
    {
        title: "Medications",
        url: "/",
        icon: "sanitizer"
    },
    {
        title: "Check-in",
        url: "",
        icon: "travel_explore"
    },
    {
        title: "Find Appointment",
        url: "",
        icon: "reduce_capacity"
    },
    {
        title: "Trackers",
        url: "",
        icon: "fitbit"
    },
    {
        title: "My Records",
        url: "",
        icon: "real_estate_agent"
    }
]