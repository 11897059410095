/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAlert from "components/VuiAlert";
import VuiAvatar from "components/VuiAvatar";

// Dashboard layout
import MainAvatar from "layouts/healthcare/components/MainAvatar";
import CategoryCard from "layouts/healthcare/components/Cards/CategoryCard";
import Reports from "layouts/healthcare/components/Reports";
import HealthBackImg from "layouts/healthcare/components/HealthBackImg"


// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

import categoryData from "./data/category";

import image from "assets/images/11.PNG";
import App from "App";
import category from "./data/category";

function Healthcare() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12} mt={10}>
            {/* <MainAvatar /> */}
            <HealthBackImg />
          </Grid>
          {
            categoryData.map(row => (
                <Grid item xs={12} md={6} xl={4}>
                  <CategoryCard data={row}/>
                </Grid>
              ))
          }
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Healthcare;
